<template>
  <section class="content">
    <div class="container-fluid">

          <div class="row">
            <h3 class="card-title">Αναθέσεις Ημέρας</h3>
          </div>


          <div class="row">
            <div class="form-group col-md-12">
              <FullCalendar :options="calendarOptions" class="user-calendar" ref="calendar" />
            </div>                     
          </div>

          <!-- Modal -->
          <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Νέα Ανάθεση</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Ανάθεσης</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateAssignment() : createAssignment()">
                    <div class="modal-body">
                      <form @submit.prevent="editmode ? updateInvoice() : createInvoice()" data-app>
                        <div class="modal-body">

                          <div class="row">
                            <div class="form-group col-md-4">
                                <v-select tabindex="1"
                                  :items="services"
                                  autofocus="autofocus"
                                  label="Υπηρεσία"
                                  item-text="name"
                                  item-value="id"
                                  dense
                                  v-model="form.service_id"
                                  :class="{ 'is-invalid': form.errors.has('service_id') }"
                                ></v-select>
                              <has-error :form="form" field="service_id"></has-error>
                            </div>
                            <div class="form-group col-md-4">
                                <v-select tabindex="1"
                                  :items="places"
                                  autofocus="autofocus"
                                  label="Τοποθεσία"
                                  item-text="name"
                                  item-value="id"
                                  clearable
                                  dense
                                  v-model="form.place_id"
                                  :class="{ 'is-invalid': form.errors.has('place_id') }"
                                ></v-select>
                              <has-error :form="form" field="place_id"></has-error>
                            </div>
                            <div class="form-group col-md-4">
                                <v-select tabindex="1"
                                  :items="users"
                                  autofocus="autofocus"
                                  label="Προσωπικό"
                                  item-text="name"
                                  item-value="id"
                                  dense
                                  v-model="form.staff1_id"
                                  :class="{ 'is-invalid': form.errors.has('staff1_id') }"
                                ></v-select>
                              <has-error :form="form" field="staff1_id"></has-error>
                            </div>
                          </div>



                          <div class="row">
                            <div class="form-group col-md-3">
                              <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                  <div class="v-input__slot">
                                    <div class="v-text-field__slot">

                                      <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                      <datepicker 
                                        :format="customDateFormatter" 
                                        v-model="form.start_date"
                                      ></datepicker>
                                    </div>
                                  </div>
                                  <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                      <div class="v-messages__wrapper"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <has-error :form="form" field="start_date"></has-error>
                            </div>

                            <div class="form-group col-md-3">
                              <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                  <div class="v-input__slot">
                                    <div class="v-text-field__slot">

                                      <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ώρα έναρξης</label>
                                      <vue-timepicker
                                      label="Ώρα έναρξης"
                                        v-model="form.start_time"
                                        :minute-interval="15"
                                        close-on-complete
                                        auto-scroll
                                        dense
                                        required
                                      ></vue-timepicker>
                                    </div>
                                  </div>
                                  <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                      <div class="v-messages__wrapper"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <has-error :form="form" field="start_time"></has-error>
                            </div>

                            <div class="form-group col-md-3">
                              <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                  <div class="v-input__slot">
                                    <div class="v-text-field__slot">

                                      <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                      <datepicker 
                                        :format="customDateFormatter" 
                                        v-model="form.end_date"
                                      ></datepicker>
                                    </div>
                                  </div>
                                  <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                      <div class="v-messages__wrapper"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <has-error :form="form" field="end_date"></has-error>
                            </div>

                            <div class="form-group col-md-3">
                              <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                  <div class="v-input__slot">
                                    <div class="v-text-field__slot">

                                      <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ώρα λήξης</label>
                                      <vue-timepicker
                                      label="Ώρα έναρξης"
                                        v-model="form.end_time"
                                        dense
                                        :minute-interval="15"
                                        required
                                        close-on-complete
                                        auto-scroll
                                      ></vue-timepicker>
                                    </div>
                                  </div>
                                  <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                      <div class="v-messages__wrapper"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <has-error :form="form" field="end_time"></has-error>
                            </div>


                          </div>





                        </div>
                        </form>                 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>


    </div>

  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import VueTimepicker from 'vue2-timepicker';

    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
    import interactionPlugin from '@fullcalendar/interaction'

    export default {
      components: {
          Datepicker,
          VueTimepicker,
          FullCalendar
        },
        data () {
            return {
                editmode: false,
                readonly: false,
                ready: false,
                bookings : [],
                services : [],
                servicesObject : {},
                places : [],
                users : [],
                form: new Form({
                    id : '',
                    type: 1,
                    service_id: '',
                    place_id: '',
                    driver_id: 0,
                    staff1_id: 0,
                    staff2_id: 0,
                    staff3_id: 0,
                    start_date: '',
                    start_time: '',
                    end_date: '',
                    end_time: '',
                    cost: '',
                    client_fullname: '',
                    client_phone: '',
                    client_email: '',
                    comments: '',
                }),
                calendarOptions: {
                  plugins: [ dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, interactionPlugin ],
                  initialView: 'resourceTimelineDay',
                  slotDuration: '00:30:00',
                  slotMinTime: "07:00:00",
                  slotmaxTime: "23:59:59",
                  height: "auto",
                  resourceLabelText: "Προσωπικό",
                  headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'resourceTimelineDay'
                  },
                  resources: this.users,
                  dateClick: info => {
                    this.form.fill([]);
                    this.form.type = 1;
                    this.editmode = false;
                    this.form.start_date = `${info.date.getFullYear()}-${info.date.getMonth()+1}-${info.date.getDate()}`;
                    this.form.end_date = `${info.date.getFullYear()}-${info.date.getMonth()+1}-${info.date.getDate()}`;
                    this.form.start_time = `${this.pad(info.date.getHours(), 2)}:${this.pad(info.date.getMinutes(), 2)}`;
                    this.form.end_time = this.nextHour();
                    this.form.staff1_id = parseInt(info.resource.id);
                    $('#addNew').modal('show');
                  },
                  eventSources: {
                    url: '/api/booking/getAll',
                    headers: {
                      'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
                    }
                  },
                  eventClick: info => {
                    this.loadBooking(info.event.id);
                  }
                },
                
            }
        },
        methods: {

          async loadBooking(id){

            await axios.get("/api/booking/getOne/" + id).then(({ data }) => {
              console.log(data);
              this.form.fill(data.data);
              this.editmode = true;
              // this.booking = data.data
            });

            $('#addNew').modal('show');

          },

          async loadPlaces(){
            await axios.get("/api/places/getListKeyValue").then(({ data }) => (this.places = data.data));
          },

          async loadServices(){
            await axios.get("/api/services/getListKeyValue").then(({ data }) => {
              this.services = data.data;
              let s = {};
              for (let i = 0; i < this.services.length; i++)
                s[this.services[i]['id']] = this.services[i]['name'];
              this.servicesObject = s;
            });
          },

          async loadUsers(){
            await axios.get("/api/user/getListKeyValue").then(({ data }) => {
              this.users = data.data;
              if(data.data)
              {
                for (let i = 0; i < this.users.length; i++)
                  this.users[i].title = this.users[i].name;
              }
              this.calendarOptions.resources = this.users;
            });
          },


          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          currentDate(){
            const current = new Date();
            // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
            return date;
          },
          currentTime(){
            const current = new Date();
            let hours = this.pad(current.getHours(), 2);
            let minutes = this.pad(Math.ceil(current.getMinutes() / 15) * 15, 2);
            if(minutes == 60){
              hours = parseInt(hours);
              hours++;
              hours = this.pad(hours % 24, 2);
              minutes = '00';
            }
            const time = `${hours}:${minutes}`;
            return time;
          },
          nextHour(){
            const timeParts = this.form.start_time.split(':');
            let hours = parseInt(timeParts[0]);
            hours++;
            hours = this.pad(hours % 24, 2);
            const minutes = timeParts[1];
            const time = `${hours}:${minutes}`;
            return time;
          },

          pad(num, size) {
              num = num.toString();
              while (num.length < size) num = "0" + num;
              return num;
          },
      
          createAssignment(){

              this.$Progress.start();

              this.form.post('/api/booking')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();

                  let calendarApi = this.$refs.calendar.getApi();
                  calendarApi.refetchEvents();
                  
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateAssignment(){
              this.$Progress.start();
              this.form.put('/api/booking/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  let calendarApi = this.$refs.calendar.getApi();
                  calendarApi.refetchEvents();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        created() {
            this.$Progress.start();

            // if(!this.$gate.user.permissionsArray.includes('invoices.edit'))
            //   this.readonly = true;

            this.loadServices();
            this.loadPlaces();
            this.loadUsers();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
