<template >
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Οχημάτων</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Ονομασία</th>
                      <th>Ενεργό</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="vehicle in vehicles.data" :key="vehicle.id">

                      <td>{{vehicle.id}}</td>
                      <td>{{vehicle.name}}</td>
                      <td>{{vehicle.active?'Ναι':'Όχι'}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(vehicle)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία οχήματος">
                              <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="vehicles" @pagination-change-page="getVehicles"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέου Οχήματος</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Οχήματος</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateVehicle() : createVehicle()">
                    <div class="modal-body">
                        <div class="form-group col-12 d-inline-block">
                            <v-text-field
                                label="Ονομασία"
                                v-model="form.name"
                                dense
                                :class="{ 'is-invalid': form.errors.has('name') }"
                            ></v-text-field>
                            <has-error :form="form" field="plate"></has-error>
                        </div>
                        <div class="form-group col-12 d-inline-block">
                          <v-select
                              v-model="form.service_id"
                              :items="servicesData"
                              item-value="id"
                              item-text="name"
                              label="Υπηρεσία"
                              type="text"
                              :return-object="false"
                              clearable
                              dense
                              required
                          ></v-select>
                        </div>
                        <div class="form-group col-12 d-inline-block">
                            <v-text-field
                                label="Θέσεις"
                                v-model="form.seats"
                                type="number"
                                min="1"
                                max="10"
                                dense
                                :class="{ 'is-invalid': form.errors.has('seats') }"
                            ></v-text-field>
                            <has-error :form="form" field="seats"></has-error>
                        </div>
                        <div class="form-group col-md-12">
                            <v-checkbox
                                v-model="form.active"
                                label="Ενεργό"
                                :true-value="1" 
                                :false-value="0"
                                dense
                            >
                            </v-checkbox>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Προσθήκη</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                vehicles : {},
                servicesData : [],
                servicesWithKeys : {},
                form: new Form({
                    id : '',
                    name: '',
                    service_id: {},
                    seats: 1,
                    active: 1
                }),
                page: 1
            }
        },
        methods: {

          getVehicles(page = 1) {

              //keep page to stay on the same page
              this.page = page;

              this.$Progress.start();
              
              axios.get('/api/vehicles?page=' + page).then(({ data }) => (this.vehicles = data.data));

              this.$Progress.finish();
          },
          loadVehicles(){
              axios.get("/api/vehicles").then(({ data }) => (this.vehicles = data.data));
          },
          loadServices(){
              axios.get("/api/services/getListKeyValue").then(({ data }) => (this.servicesData = data.data));
          },
          editModal(vehicle){
              // vehicle.service_id = this.servicesWithKeys[String(vehicle.service_id)];

              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(vehicle);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createVehicle(){
              this.$Progress.start();

              console.log(this.form);
              this.form.post('/api/vehicles')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadVehicles();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateVehicle(){
              this.$Progress.start();
              this.form.put('/api/vehicles/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  
                  this.getVehicles(this.page);
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadServices();
            this.loadVehicles();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
