<template>
  <section class="content">
    <div class="container-fluid" data-app>
        <div class="row">
          <div class="col-12">


            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="loadFoundBookings()">
                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                            label="ID κράτησης"
                            v-model="searchID"
                            dense
                            clearable
                            placeholder="Πληκτρολογήστε τον αριθμό κράτησης"
                            @change="loadFoundBookings()"
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Όνομα, email ή τηλέφωνο πελάτη"
                            v-model="searchClient"
                            dense
                            clearable
                            placeholder="Πληκτρολογήστε τον αριθμό τηλεφώνο ή το όνομα του πελάτη"
                            @change="loadFoundBookings()"
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. έναρξης κράτησης</label>
                                  <datepicker
                                    :format="customDateFormatter" 
                                    v-model="searchStartDate"
                                    @input="loadFoundBookings()"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                    <div class="row">
                      <div class="form-group col-md-4">
                          <v-select tabindex="1"
                            :items="services"
                            label="Υπηρεσία"
                            item-text="name"
                            item-value="id"
                            clearable
                            dense
                            v-model="searchService"
                            @change="loadFoundBookings()"
                          ></v-select>
                        </div>
                        <div class="col-md-4">
                        <v-text-field
                            label="Αναζήτηση σχολίου"
                            v-model="searchComment"
                            dense
                            clearable
                            placeholder="Πληκτρολογήστε σχόλιο"
                            @change="loadFoundBookings()"
                        ></v-text-field>
                      </div>
                    </div>

                </form>

              </div>
            </div>
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Κρατήσεων</h3>

                <div class="card-tools">

                  <router-link :to="{ path: '/booking/download' }">
                    <button type="button" class="btn btn-sm bg-primary white">
                        <i class="fa fa-download"></i>
                        Λήψη Λίστας
                    </button>
                  </router-link>
                  
                  <router-link :to="{ path: '/booking/place' }">
                    <button type="button" class="btn btn-sm bg-primary white">
                        <i class="fa fa-plus-square"></i>
                        Νέα ανάθεση
                    </button>
                  </router-link>
                  
                  <button type="button" class="btn btn-sm bg-primary white" v-on:click="newBooking">
                      <i class="fa fa-plus-square"></i>
                      Νέα κράτηση
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Υπηρεσία</th>
                      <th>Έναρξη</th>
                      <th>Λήξη</th>
                      <th>Πελάτης</th>
                      <th>Τηλέφωνο</th>
                      <th>Άτομα</th>
                      <th>Κόστος</th>
                      <th>Υπόλοιπο</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="booking in bookings.data" :key="booking.id">
                      <td>{{ booking.id }} {{ booking.travelotopos_code != null?`(${booking.travelotopos_code})`:'' }}</td>
                      <td>{{ (servicesObject?servicesObject[booking.service_id]:'') }}</td>
                      <td>{{ customDateFormatter(booking.start_date) + " " + booking.start_time }}</td>
                      <td>{{ customDateFormatter(booking.end_date) + " " + booking.end_time }}</td>
                      <td>{{ booking.client_fullname }}</td>
                      <td v-if="booking.client_phone.length > 0"><a v-bind:href="'tel:' + booking.client_phone">{{ booking.client_phone }}</a></td>
                      <td v-if="booking.client_phone.length == 0"></td>
                      <td>{{ booking.people }}</td>
                      <td>{{ booking.cost }}€</td>
                      <td>{{ booking.balance }}€</td>

                      <td>
                        <div class="row pt-3">
                          <!-- <button v-show="editmode" type="button" class="btn btn-primary" @click="downloadPDF(invoice)"><i class="fa fa-download white"></i></button> -->
                          <button class="bg-primary btn btn-sm" title="Προβολή πληρωμών" @click="viewPayments(booking.id)">
                            <i class="fa fa-plus white"></i>
                          </button>
                          <button class="bg-primary btn btn-sm" title="Επεξεργασία Κράτησης" @click="editBooking(booking)">
                            <i class="fa fa-edit white"></i>
                          </button>
                          <button class="bg-danger btn btn-sm" title="Διαγραφή Κράτησης" @click="deleteBooking(booking.id)">
                            <i class="fa fa-times white"></i>
                          </button>
                        </div>
                      </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :limit="12" :data="bookings" @pagination-change-page="loadFoundBookings"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>









          <!-- Modal -->
          <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Νέα Ανάθεση</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Ανάθεσης</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateBooking() : createBooking()">
                    <div class="modal-body">

                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="services"
                                label="Υπηρεσία"
                                item-text="name"
                                item-value="id"
                                dense
                                v-model="form.service_id"
                                :class="{ 'is-invalid': form.errors.has('service_id') }"
                                @change="checkAvailableStaffVehicles"
                              ></v-select>
                            <has-error :form="form" field="service_id"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="places"
                                label="Τοποθεσία"
                                item-text="name"
                                item-value="id"
                                clearable
                                dense
                                v-model="form.place_id"
                                :class="{ 'is-invalid': form.errors.has('place_id') }"
                              ></v-select>
                            <has-error :form="form" field="place_id"></has-error>
                          </div>
                          <div class="form-group col-md-4 date-input">
                            <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                    <datepicker 
                                      :format="customDateFormatter" 
                                      v-model="form.start_date"
                                      @closed="dateSelected"
                                    ></datepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <has-error :form="form" field="start_date"></has-error>
                          </div>

                        </div>

                        <div class="row mb-0" v-show="showVehicleCalendar">
                          <div class="form-group col-md-12">
                            <h4>Οχήματα</h4>
                            <FullCalendar :options="vehicleCalendarOptions" :events="vehicleEvents" class="user-calendar" ref="vehicleCalendar" />
                          </div>
                        </div>

                        <div class="row mb-0" v-show="showUserCalendar">
                          <div class="form-group col-md-12">
                            <h4>Προσωπικό</h4>
                            <FullCalendar :options="userCalendarOptions" class="user-calendar" ref="userCalendar" />
                          </div>
                        </div>


                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="vehicles"
                                label="Όχημα 1"
                                item-text="name"
                                item-value="id"
                                required
                                clearable
                                dense
                                v-model="form.vehicle_id1"
                                @change="changeMaxPeople()"
                                :class="{ 'is-invalid': form.errors.has('vehicle_id1') }"
                              ></v-select>
                            <has-error :form="form" field="vehicle_id1"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="vehicles"
                                label="Όχημα 2"
                                item-text="name"
                                item-value="id"
                                required
                                clearable
                                dense
                                v-model="form.vehicle_id2"
                                @change="changeMaxPeople()"
                                :class="{ 'is-invalid': form.errors.has('vehicle_id2') }"
                              ></v-select>
                            <has-error :form="form" field="vehicle_id2"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="vehicles"
                                label="Όχημα 3"
                                item-text="name"
                                item-value="id"
                                required
                                clearable
                                dense
                                v-model="form.vehicle_id3"
                                @change="changeMaxPeople()"
                                :class="{ 'is-invalid': form.errors.has('vehicle_id3') }"
                              ></v-select>
                            <has-error :form="form" field="vehicle_id3"></has-error>
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="drivers"
                                label="Οδηγός"
                                item-text="name"
                                item-value="id"
                                clearable
                                dense
                                v-model="form.driver_id"
                                :class="{ 'is-invalid': form.errors.has('driver_id') }"
                              ></v-select>
                            <has-error :form="form" field="driver_id"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="users"
                                label="Προσωπικό 1"
                                item-text="name"
                                item-value="id"
                                clearable
                                dense
                                v-model="form.staff1_id"
                                :class="{ 'is-invalid': form.errors.has('staff1_id') }"
                              ></v-select>
                            <has-error :form="form" field="staff1_id"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="users"
                                label="Προσωπικό 2"
                                item-text="name"
                                item-value="id"
                                clearable
                                dense
                                v-model="form.staff2_id"
                                :class="{ 'is-invalid': form.errors.has('staff2_id') }"
                              ></v-select>
                            <has-error :form="form" field="staff2_id"></has-error>
                          </div>
                        </div>



                        <div class="row">


                          <div class="form-group col-md-4">
                            <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted" :class="{ 'is-invalid': form.errors.has('start_time') }">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ώρα έναρξης</label>
                                    <vue-timepicker
                                        v-model="form.start_time"
                                        :minute-interval="15"
                                        :hour-range="[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]"
                                        hide-disabled-hours
                                        format="HH:mm"
                                        close-on-complete
                                        auto-scroll
                                        dense
                                        required
                                      ></vue-timepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <has-error :form="form" field="start_time"></has-error>
                          </div>

                          <div class="form-group col-md-4">
                              <v-select tabindex="1"
                                :items="hours"
                                label="Ώρες"
                                dense
                                v-model="form.hours"
                                :class="{ 'is-invalid': form.errors.has('staff3_id') }"
                              ></v-select>
                            <has-error :form="form" field="staff3_id"></has-error>
                          </div>

                          <div class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="Κόστος"
                                dense
                                v-model="form.cost"
                                type="number"
                                step="1"
                                :class="{ 'is-invalid': form.errors.has('cost') }"
                              ></v-text-field>
                            <has-error :form="form" field="cost"></has-error>
                          </div>

                          <!--<div class="form-group col-md-4">
                            <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Λήξης</label>
                                    <datepicker 
                                      :format="customDateFormatter" 
                                      v-model="form.end_date"
                                    ></datepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <has-error :form="form" field="end_date"></has-error>
                          </div>


                          <div class="form-group col-md-4">
                            <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ώρα λήξης</label>
                                    <vue-timepicker
                                        v-model="form.end_time"
                                        :minute-interval="15"
                                        close-on-complete
                                        :hour-range="[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]"
                                        hide-disabled-hours
                                        auto-scroll
                                        dense
                                        required
                                      ></vue-timepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <has-error :form="form" field="end_time"></has-error>
                          </div>-->

                        </div>


                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="Ονοματεπώνυμο"
                                clearable
                                dense
                                v-model="form.client_fullname"
                                :class="{ 'is-invalid': form.errors.has('client_fullname') }"
                              ></v-text-field>
                            <has-error :form="form" field="client_fullname"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="Τηλέφωνο επικοινωνίας"
                                clearable
                                dense
                                v-model="form.client_phone"
                                :class="{ 'is-invalid': form.errors.has('client_phone') }"
                              ></v-text-field>
                            <has-error :form="form" field="client_phone"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="Email επικοινωνίας"
                                clearable
                                dense
                                v-model="form.client_email"
                                :class="{ 'is-invalid': form.errors.has('client_email') }"
                              ></v-text-field>
                            <has-error :form="form" field="client_email"></has-error>
                          </div>
                        </div>


                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="ΑΔΤ"
                                clearable
                                dense
                                v-model="form.client_adt"
                                :class="{ 'is-invalid': form.errors.has('client_adt') }"
                              ></v-text-field>
                            <has-error :form="form" field="client_adt"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="Αριθμός ατόμων"
                                dense
                                v-model="form.people"
                                ref="people"
                                min="1"
                                max="10"
                                :class="{ 'is-invalid': form.errors.has('people') }"
                                type="number"
                              ></v-text-field>
                            <has-error :form="form" field="people"></has-error>
                          </div>



                        </div>

                        <div class="row">
                          <div v-if="!editmode" class="form-group col-md-4">
                              <v-text-field tabindex="1"
                                label="Προκαταβολή"
                                dense
                                v-model="form.pay"
                                type="number"
                                step="1"
                                :class="{ 'is-invalid': form.errors.has('pay') }"
                              ></v-text-field>
                            <has-error :form="form" field="pay"></has-error>
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-12">
                              <div><label for="image">Εικόνα ταυτότητας</label></div>
                              <v-file-input accept="image/*" clearable label="File input"  v-model="form.imageFile"></v-file-input>
                              <has-error :form="form" field="image"></has-error>
                              <div v-if="form.image != null">
                                  <img class="img-fluid" :src="form.image.replace('public/', '/storage/')" alt="">
                              </div>
                            </div>
                          </div>

                        <div class="row">
                          <div class="form-group col-md-12">
                              <v-textarea tabindex="1"
                                label="Σχόλια"
                                clearable
                                dense
                                v-model="form.comments"
                                :class="{ 'is-invalid': form.errors.has('comments') }"
                              ></v-textarea>
                            <has-error :form="form" field="comments"></has-error>
                          </div>
                        </div>
            
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>






          <!-- Modal -->
          <div class="modal fade" id="payments" tabindex="-1" role="dialog" aria-labelledby="payments" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Πληρωμές</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="createPayment()">
                    <div class="modal-body">

                        <div class="row">
                          <div class="form-group col-md-6">
                              <v-text-field tabindex="1"
                                label="Περιγραφή"
                                dense
                                required
                                v-model="paymentForm.description"
                                :class="{ 'is-invalid': paymentForm.errors.has('description') }"
                              ></v-text-field>
                            <has-error :form="paymentForm" field="description"></has-error>
                          </div>
                          <div class="form-group col-md-6">
                              <v-text-field tabindex="1"
                                label="Ποσό"
                                dense
                                required
                                v-model="paymentForm.amount"
                                :class="{ 'is-invalid': paymentForm.errors.has('amount') }"
                              ></v-text-field>
                            <has-error :form="paymentForm" field="amount"></has-error>
                          </div>

                        </div>
            
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Περιγραφή</th>
                      <th>Ποσό</th>
                      <th>Ημ. Πληρωμής</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="payment in payments.data" :key="payment.id">
                      <td>{{ payment.id }}</td>
                      <td>{{ payment.description }}</td>
                      <td>{{ payment.amount }}€</td>
                      <td>{{ customDateFormatter(payment.payment_date) }}</td>

                      <td>
                        <div class="row pt-3">
                          <button class="bg-danger btn btn-sm" title="Διαγραφή Πληρωμής" @click="deletePayment(payment.id, paymentForm.id)">
                            <i class="fa fa-times white"></i>
                          </button>
                        </div>
                      </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>

            </div>










    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import VueTimepicker from 'vue2-timepicker';
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
    import interactionPlugin from '@fullcalendar/interaction'
    import { serialize } from 'object-to-formdata'

    import {pad, currentDate, getDate, currentTime, nextHour} from '../../common-functions.js';

    export default {
        components: {
          Datepicker,
          VueTimepicker,
          FullCalendar
        },
        data () {
            return {
                editmode: false,
                bookings : {},
                hours : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                services : [],
                servicesObject : {},
                places : [],
                users : [],
                drivers : [],
                vehicles : [],
                vehicle_ids: [],
                vehicleEvents: [],
                payments: {},
                form: new Form({
                  id : '',
                  type: 1,
                  service_id: '',
                  place_id: '',
                  vehicle_id1: '',
                  vehicle_id2: '',
                  vehicle_id3: '',
                  driver_id: 0,
                  staff1_id: 0,
                  staff2_id: 0,
                  staff3_id: 0,
                  start_date: '',
                  start_time: '',
                  end_date: '',
                  end_time: '',
                  hours: 1,
                  people: 1,
                  cost: '',
                  pay: 0,
                  client_fullname: '',
                  client_phone: '',
                  client_email: '',
                  client_adt: '',
                  imageFile: null,
                  image: null,
                  comments: '',
                }),
                paymentForm: new Form({
                  amount: '',
                  description: '',
                  booking_id: '',
                  payment_date: '',
                }),


                vehicleCalendarOptions: {
                  plugins: [ dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, interactionPlugin ],
                  initialView: 'resourceTimelineDay',
                  slotDuration: '00:30:00',
                  slotMinTime: "07:00:00",
                  slotmaxTime: "23:59:59",
                  height: "auto",
                  headerToolbar: false,
                  resources: this.vehicles,
                },

                userCalendarOptions: {
                  plugins: [ dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, interactionPlugin ],
                  initialView: 'resourceTimelineDay',
                  slotDuration: '00:30:00',
                  slotMinTime: "07:00:00",
                  slotmaxTime: "23:59:59",
                  height: "auto",
                  headerToolbar: false,
                  resources: this.users,
                },

                showVehicleCalendar: false,
                showUserCalendar: false,

                searchStartDate: '',
                searchService: '',
                searchComment: '',
                searchClient: '',
                searchID: ''
            }
        },
        methods: {

          loadFoundBookings(){
              if(this.searchStartDate != null && this.searchStartDate.length > 10)
                this.searchStartDate = this.search_issue_date.substring(0, 10);

              //search all fields
              let searchFields = ['searchStartDate', 'searchClient', 'searchID', 'searchComment', 'searchService'];
              let filter = [];
              let data = '';
              for (let i = 0; i < searchFields.length; i++)
              {
                if(this[searchFields[i]] != null && this[searchFields[i]] != '')
                {
                  if(searchFields[i] == 'searchStartDate')
                    data =  this.$moment(this[searchFields[i]]).format('yyyy-MM-DD');
                  else
                    data =  this[searchFields[i]];

                  filter.push(searchFields[i] + '=' + data);
                }
              }

              let filterText = '';
              if(filter.length > 0)
                filterText = '&' + filter.join('&');
                
              this.loadBookings(1, filterText);
          },

          loadBookings(page, filter){
            axios.get("/api/booking" + `?page=${page}${filter}`).then(({ data }) => (this.bookings = data));
          },

          loadPayments(id){
            axios.get("/api/payments/getList/" + id).then(({ data }) => (this.payments = data));
          },


          async loadPlaces(){
            await axios.get("/api/places/getListKeyValue").then(({ data }) => (this.places = data.data));
          },

          async loadServices(){
            await axios.get("/api/services/getListKeyValue").then(({ data }) => {
              this.services = data.data;
              let s = {};
              for (let i = 0; i < this.services.length; i++)
                s[this.services[i]['id']] = this.services[i]['name'];
              this.servicesObject = s;
            });
          },

          async loadUsers(){
            await axios.get("/api/user/getListKeyValue").then(({ data }) => {
              this.users = data.data;
              if(data.data)
              {
                for (let i = 0; i < this.users.length; i++)
                  this.users[i].title = this.users[i].name;
              }
              // this.calendarOptions.resources = this.users;
            });
          },

          async checkAvailableStaffVehicles(){
            await axios.get("/api/vehicles/getListKeyValue/" + this.form.service_id).then(async ({ data }) => {
              this.vehicles = data.data;

              if(this.vehicles.length > 0)
              {
                this.showVehicleCalendar = true;

                let vehicle_ids = [];
                if(data.data)
                {
                  for (let i = 0; i < this.vehicles.length; i++)
                  {
                    this.vehicles[i].title = this.vehicles[i].name;
                    vehicle_ids.push(this.vehicles[i].id);
                  }
                }
                this.vehicleCalendarOptions.resources = this.vehicles;

                this.vehicleCalendarOptions.eventSources = [{
                  url: '/api/booking/vehicleGetAll/' + vehicle_ids.join(","),
                  headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
                    'X-XSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
                  }
                }]
              }

              let users = [];
              let drivers = [];
              for (let i = 0; i < this.users.length; i++)
              {
                let u = JSON.parse(JSON.stringify(this.users[i]));
                if(this.users[i].services.includes(this.form.service_id))
                {
                  drivers.push(u);
                  u.title += " (Οδηγός)";
                }
                users.push(u);
              }
              this.drivers = drivers;

              this.userCalendarOptions.resources = users;
              this.showUserCalendar = true;
              this.userCalendarOptions.eventSources = [{
                url: '/api/booking/getAll',
                headers: {
                  'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
                  'X-XSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
                }
              }]

              this.changeMaxPeople();
            });
          },

          dateSelected(){
            if(this.form.start_date instanceof Date)
              this.form.start_date = getDate(this.form.start_date);

            this.form.end_date = this.form.start_date;

            const vehicleCalendarApi = this.$refs.vehicleCalendar.getApi();
            vehicleCalendarApi.gotoDate(this.form.start_date);

            const userCalendarApi = this.$refs.userCalendar.getApi();
            userCalendarApi.gotoDate(this.form.start_date);

            this.checkAvailableStaffVehicles();
          },

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },

          newBooking(){
            this.form.reset();
            this.form.start_time = {
              HH: '',
              mm: ''
            };
            this.form.end_time = {
              HH: '',
              mm: ''
            };
            
            this.form.type = 1;
            this.form.people = 1;
            this.editmode = false;
            // this.form.start_date = currentDate();
            // this.vehicleCalendarOptions.initialDate = this.form.start_date;
            // this.userCalendarOptions.initialDate = this.form.start_date;
            this.showVehicleCalendar = false;
            this.showUserCalendar = false;
            $('#addNew').modal('show');
          },

          editBooking(booking){
            this.form.fill(booking);
            this.form.type = 1;
            this.editmode = true;
            // this.form.start_date = currentDate();
            this.vehicleCalendarOptions.initialDate = this.form.start_date;
            this.userCalendarOptions.initialDate = this.form.start_date;
            this.showVehicleCalendar = true;
            this.showUserCalendar = true;
            this.checkAvailableStaffVehicles()
            this.changeMaxPeople();
            $('#addNew').modal('show');
          },



          createBooking(){

            this.$Progress.start();

            this.form.submit('post', 'api/booking', {
              transformRequest: [function (data) {
                  return serialize(data)
              }],
            })
            .then((data)=>{
              if(data.data.success){
                $('#addNew').modal('hide');

                Toast.fire({
                      icon: 'success',
                      title: data.data.message
                  });

                this.loadFoundBookings();

                this.$Progress.finish();
                
              } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Some error occured! Please try again'
                });


                this.$Progress.failed();
              }
            })
            .catch(()=>{

                Toast.fire({
                    icon: 'error',
                    title: 'Some error occured! Please try again'
                });
            })
            },


            updateBooking(){
            this.$Progress.start();

            this.form.submit('post', 'api/booking/'+this.form.id, {
              transformRequest: [function (data) {
                  return serialize(data)
              }],
            })
            // this.form.put('/api/booking/'+this.form.id)
            .then((response) => {
                // success
                $('#addNew').modal('hide');
                Toast.fire({
                  icon: 'success',
                  title: response.data.message
                });

                this.loadFoundBookings();

                this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

            })
            .catch(() => {
                this.$Progress.fail();
            });

            },

            deleteBooking(id){

                Swal.fire({
                    title: 'Είστε σίγουροι;',
                    text: "Δεν υπάρχει δυνατότητα ανάκτησης αυτής της κράτησης",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Νάι',
                    cancelButtonText: 'Όχι'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                          this.form.delete('/api/booking/'+id).then(()=>{
                                        Swal.fire(
                                        'Διαγράφηκε!',
                                        'Η κράτηση έχει διαγραφεί',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadFoundBookings();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },


            viewPayments(id){
              this.paymentForm.reset();
              this.paymentForm.booking_id = id;
              this.payments = {};
              this.loadPayments(id);
              $('#payments').modal('show');
            },

            createPayment(){
              this.$Progress.start();
              this.paymentForm.post('/api/payments')
              .then((data)=>{
                if(data.data.success){
                  this.paymentForm.reset();
                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });

                  this.loadPayments(data.data.data.booking_id);
                  this.loadFoundBookings();

                  this.$Progress.finish();
                  
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
            },

            deletePayment(id, booking_id){

              Swal.fire({
                  title: 'Είστε σίγουροι;',
                  text: "Δεν υπάρχει δυνατότητα ανάκτησης αυτής της πληρωμής",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Νάι',
                  cancelButtonText: 'Όχι'
                  }).then((result) => {

                      // Send request to the server
                      if (result.value) {
                        this.paymentForm.delete('/api/payments/'+id).then(()=>{
                                      Swal.fire(
                                      'Διαγράφηκε!',
                                      'Η πληρωμή έχει διαγραφεί',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadPayments(this.paymentForm.booking_id);
                                  this.loadFoundBookings();
                              }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
                      }
                  })
              },


            changeMaxPeople(){
              let max = 0;

              for (let i = 0; i < this.vehicles.length; i++)
              {
                if(this.vehicles[i]['id'] == this.form.vehicle_id1 || this.vehicles[i]['id'] == this.form.vehicle_id2 || this.vehicles[i]['id'] == this.form.vehicle_id3)
                {
                  max += this.vehicles[i]['seats'];
                }
              }
              const inputElement = this.$refs.people.$el.querySelector('input');
              inputElement.max = max;
            }


        },
        mounted() {
            //check if we should open new client form
            let hash = window.location.hash;
            if(hash.length > 0)
            {
              hash = hash.substring(1);
              if(hash == 'new')
              {
                  this.newBooking();
              }
              
            }
            
        },
        async created() {
            this.$Progress.start();

            await this.loadServices();
            await this.loadPlaces();
            await this.loadUsers();

            this.form.start_date = currentDate();
            this.searchStartDate = currentDate();
            this.loadFoundBookings();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
