
// export const customDateFormatter = (date) => {
//   return this.$moment(date).format('DD/MM/yyyy');
// };

export const currentDate = () => {
  const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const date = `${current.getFullYear()}-${pad(current.getMonth()+1, 2)}-${pad(current.getDate(), 2)}`;
  return date;
};

export const getDate = (current) => {
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const date = `${current.getFullYear()}-${pad(current.getMonth()+1, 2)}-${pad(current.getDate(), 2)}`;
  return date;
};

export const currentTime = () => {
  const current = new Date();
  let hours = pad(current.getHours(), 2);
  let minutes = pad(Math.ceil(current.getMinutes() / 15) * 15, 2);
  if(minutes == 60){
    hours = parseInt(hours);
    hours++;
    hours = pad(hours % 24, 2);
    minutes = '00';
  }
  const time = `${hours}:${minutes}`;
  return time;
};

export const nextHour = () => {
  const timeParts = this.form.start_time.split(':');
  let hours = parseInt(timeParts[0]);
  hours++;
  hours = pad(hours % 24, 2);
  const minutes = timeParts[1];
  const time = `${hours}:${minutes}`;
  return time;
};

export const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
};

