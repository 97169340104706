<template>
    <section class="content">
        <div class="row">
            <div class="form-group col-md-12">
                <h4>Προσωπικό</h4>
                <FullCalendar :options="calendarOptions" class="user-calendar" ref="calendar" />
            </div>                     
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <h4>Οχήματα</h4>
                <FullCalendar :options="vehicleCalendarOptions" class="user-calendar" ref="vehicleCalendar" />
            </div>                     
        </div>
    </section>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import resourceTimelinePlugin from '@fullcalendar/resource-timeline'


    export default {
        components: {
          FullCalendar
        },
        data () {
            return {
                sums: {},
                users: [],
                vehicles: [],
                calendarOptions: {
                  plugins: [ dayGridPlugin, timeGridPlugin, resourceTimelinePlugin ],
                  initialView: 'resourceTimelineDay',
                  slotDuration: '00:30:00',
                  slotMinTime: "07:00:00",
                  // slotmaxTime: "23:59:59",
                  height: "auto",
                  // resourceLabelText: "Προσωπικό",
                  headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                  },

                  resources: this.users,
                  eventSources: {
                    url: '/api/booking/getAll',
                    headers: {
                      'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
                    }
                  },
                },
                vehicleCalendarOptions: {
                  plugins: [ dayGridPlugin, timeGridPlugin, resourceTimelinePlugin ],
                  initialView: 'resourceTimelineDay',
                  slotDuration: '00:30:00',
                  slotMinTime: "07:00:00",
                  // slotmaxTime: "23:59:59",
                  height: "auto",
                  headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                  },
                  resources: this.vehicles,
                  eventSources: [{
                  url: '/api/booking/vehicleGetAll/',
                  headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
                    'X-XSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
                  }
                }]
                },
            }
        },
        methods: {
            async loadUsers(){
            await axios.get("/api/user/getListKeyValue").then(({ data }) => {
              this.users = data.data;
              if(data.data)
              {
                for (let i = 0; i < this.users.length; i++)
                  this.users[i].title = this.users[i].name;
              }
              this.calendarOptions.resources = this.users;
            });
          },
            loadVehicles(){
                axios.get("/api/vehicles/getListKeyValue").then(async ({ data }) => {
                    this.vehicles = data.data;
                    for (let i = 0; i < this.vehicles.length; i++)
                        this.vehicles[i].title = this.vehicles[i].name;

                    this.vehicleCalendarOptions.resources = this.vehicles;

                });
            }
        },
        mounted() {
          if(window.innerWidth < 992)
            document.querySelector('#sidebar-overlay').click();
        },
        created() {
            this.loadUsers();
            this.loadVehicles();
        }
    }
</script>
