<template>
  <section class="content">
    <div class="container-fluid">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Κράτησης</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Κράτησης</h5>
                </div>

                <form @submit.prevent="editmode ? updateInvoice() : createInvoice()" data-app>

                    <div class="modal-body">

                      <div class="row">
                        <div class="form-group col-md-12">
                            <v-select tabindex="1"
                              :items="services"
                              autofocus="autofocus"
                              label="Υπηρεσία"
                              item-text="name"
                              item-value="id"
                              dense
                              v-model="form.service_id"
                              :class="{ 'is-invalid': form.errors.has('service_id') }"
                              :readonly="editmode || readonly"
                            ></v-select>
                          <has-error :form="form" field="service_id"></has-error>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="form.start_date"
                                    :readonly="editmode || readonly"
                                    v-on:change="getAvailability"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="start_date"></has-error>
                        </div>

                        <div class="form-group col-md-3">
                          <vue-timepicker
                          label="Ώρα έναρξης"
                            v-model="form.start_time"
                            :readonly="readonly"
                            dense
                            :minute-interval="15"
                            required
                            close-on-complete
                            auto-scroll
                          ></vue-timepicker>

                          <has-error :form="form" field="start_time"></has-error>
                        </div>

                        <div class="form-group col-md-3">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="form.end_date"
                                    :readonly="editmode || readonly"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="end_date"></has-error>
                        </div>

                        <div class="form-group col-md-3">
                          <vue-timepicker
                          label="Ώρα έναρξης"
                            v-model="form.end_time"
                            :readonly="readonly"
                            dense
                            :minute-interval="15"
                            required
                            close-on-complete
                            auto-scroll
                          ></vue-timepicker>

                          <has-error :form="form" field="end_time"></has-error>
                        </div>


                      </div>


                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Έκδοση</button>
                        <button v-show="editmode" type="button" class="btn btn-primary" @click="downloadPDF()">Λήψη Παραστατικού</button>
                        <button v-show="editmode" type="button" class="btn btn-info" @click="previewPDF()">Προεπισκόπιση Παραστατικού</button>
                        <button v-show="editmode && $gate.user.permissionsArray.includes('invoices.edit')" type="button" class="btn btn-warning" @click="emailInvoice()">Αποστολή Παραστατικού</button>
                    </div>
                    </div>
                  </form>
    </div>

    <div v-if="editmode" class="col-12" id="pdfPreview" ref="embeded">
      <embed width='100%' height='100%' :src="pdfOutput"/>
    </div>

  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueTimepicker from 'vue2-timepicker'


    export default {
      components: {
          Datepicker,
          VueTimepicker
        },
        data () {
            return {
                editmode: false,
                readonly: false,
                ready: false,
                onlyCompaniesLoaded: false,
                services : [],
                form: new Form({
                    id : '',
                    service_id: '',
                    start_date: '',
                    start_time: '',

                    
                }),
                
            }
        },
        methods: {
          async loadClients(){
            let in_array = [];
            if(this.form.client_sender_id > 0)
              in_array.push(this.form.client_sender_id);
            if(this.form.client_receiver_id > 0)
              in_array.push(this.form.client_receiver_id);
            if(this.form.client_issuer_id > 0)
              in_array.push(this.form.client_issuer_id);
            if(this.form.client_charged_id > 0)
              in_array.push(this.form.client_charged_id);

            // await axios.get("/api/clients/getList?in=" + in_array.join(',')).then(({ data }) => {
            //   this.senderClients = data.data
            //   this.receiverClients = data.data
            //   this.issuerClients = data.data


            //   let clientsWithKeys = {};
            //   for (let i = 0; i < data.data.length; i++)
            //     clientsWithKeys[data.data[i].id] = data.data[i];
            //   this.clientsWithKeys = clientsWithKeys;
            // });
          },

          searchClients(field){
            let searchVar = '',
                dataVar = '';
            if(field == 'sender')
            {
              searchVar = 'searchSender';
              dataVar = 'senderClients';
            }
            else if(field == 'receiver')
            {
              searchVar = 'searchReceiver';
              dataVar = 'receiverClients';
            }
            else if(field == 'issuer')
            {
              searchVar = 'searchIssuer';
              dataVar = 'issuerClients';
            }

            if(this[searchVar] != null && this[searchVar].length == 3)
            {
              this.loadingSenders = true;
              axios.get("/api/clients/getList?search="+this[searchVar]).then(({ data }) => (this[dataVar] = data.data));
              this.loadingSenders = false;
            }
          },
          loadInvoice (){
            if(this.editmode == false)
              return;

            axios.get("/api/invoices/getOne/" + this.form.id).then(({ data }) => (this.parseInvoiceData(data.data)));
          },
          async parseInvoiceData(data){
            this.form.fill(data);

            //count invoice items for v-for to render the appropriate rows
            this.invoiceItemsRowsCount = this.form.quantities.length;

            //load after everything so that we have active invoice type
            // await this.loadAreas();
            // await this.loadInvoiceSeries();
            // await this.loadClients();

            //να φέρει τα στοιχεία διακίνησης που έχουν επιλέγει και άλλα που δεν έχουν επιλεγεί από κάπου ακόμα.
            if(this.form.invoice_type_id == 6)
              this.searchForStoixeiaDiakinisis(this.form.id);

            //caclulate invoice items row for every row
            if(this.form.invoice_type_id != 3)
            {
              for (let i = 1; i < this.invoiceItemsRowsCount; i++)
                this.calculateProductInitialCost(i);
  
              this.calculateSums();
            }
          },
          loadInvoiceTypes(){
            axios.get("/api/invoiceTypes/getList").then(({ data }) => (this.parseInvoiceTypes(data.data)));
          },
          loadQuantityTypes(){
            axios.get("/api/quantityTypes/getListKeyValue").then(({ data }) => (this.quantityTypes = data.data));
          },
          parseInvoiceTypes(data){
            //αφαίρεση ειδικού ακυρωτικού στοιχείου αν δεν είμαστε σε edit
            if(!this.editmode)
            {
              for (let i = 0; i < data.length; i++)
              {
                if(data[i].id == 4)
                  data.splice(i, 1);              
              }
            }

            let invoiceTypesWithKeys = {};
            for (let i = 0; i < data.length; i++)
              invoiceTypesWithKeys[data[i].id] = data[i].name;


            this.invoiceTypes = data;
            this.invoiceTypesWithKeys = invoiceTypesWithKeys;
          },
          async loadAreas(){
            await axios.get("/api/areas/getList").then(({ data }) => (this.parseAreasData(data.data)));
          },
          parseAreasData(data){
            let areasArray = [];
            for (const id in data)
                areasArray.push(data[id]);

            this.areasWithKeys = data;
            this.areas = areasArray;

            //render areas correctly if in edit mode
            if(this.editmode)
            {
              this.form.to_area_id = this.areasWithKeys[this.form.to_area_id];
              this.form.from_area_id = this.areasWithKeys[this.form.from_area_id];
            }
          },
          loadServices(){
                axios.get("/api/services/getListKeyValue").then(({ data }) => (this.services = data.data));
          },
          loadVehicles(){
            axios.get("/api/vehicles/getList").then(({ data }) => (this.parseVehicles(data.data)));
          },
          parseVehicles(data){
            let vehiclesWithKeys = {};
            for (let i = 0; i < data.length; i++){
                vehiclesWithKeys[data[i].id] = data[i].plate;
            }
            this.vehicles = data;             
            this.vehiclesWithKeys = vehiclesWithKeys;
          },
          parseChargedClients(charges){
            let chargedClientsWithKeys = {};
            for (let i = 0; i < charges.length; i++) {
                chargedClientsWithKeys[charges[i].id] = charges[i].name;
            }
            this.chargedClientsWithKeys = chargedClientsWithKeys;
          },
         loadPaymentTypes(){
           axios.get("/api/paymentTypes/getList").then(({ data }) => (this.paymentTypes = data.data));
        //  loadPaymentTypes(){
        //     axios.get("/api/paymentTypes/getList").then(({ data }) => (data.data));
          },



          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          currentDate(){
            const current = new Date();
            // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
            return date;
          },
          currentTime(){
            const current = new Date();
            let hours = this.pad(current.getHours(), 2);
            let minutes = this.pad(Math.ceil(current.getMinutes() / 15) * 15, 2);
            if(minutes == 60){
              hours = parseInt(hours);
              hours++;
              hours = this.pad(hours % 24, 2);
              minutes = '00';
            }
            const time = `${hours}:${minutes}`;
            return time;
          },
          nextHour(){
            const timeParts = this.form.start_time.split(':');
            let hours = parseInt(timeParts[0]);
            hours++;
            hours = this.pad(hours % 24, 2);
            const minutes = timeParts[1];
            const time = `${hours}:${minutes}`;
            return time;
          },

          pad(num, size) {
              num = num.toString();
              while (num.length < size) num = "0" + num;
              return num;
          },



          //Fuction for creating pdf. Should be moved to app.js
       
          createInvoice(){

              this.$Progress.start();

              this.form.post('/api/invoices')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();


                  let id = data.data.data.id;
                  // this.form.id = data.data.data.id;
                  // this.editmode = true;

                  this.$router.push({path: '/invoices/edit/' + id + '#embeded'})
                  
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateInvoice(){
              this.$Progress.start();
              this.form.put('/api/invoices/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');


              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            //check if we should go down
            let hash = window.location.hash;
            if(hash.length > 0)
            {
              hash = hash.substring(1);
              // this.$refs['embeded'].scrollIntoView({top: 0, left: 0, behavior: 'smooth'});
            }
        },
        created() {
            this.$Progress.start();

            // if(!this.$gate.user.permissionsArray.includes('invoices.edit'))
            //   this.readonly = true;


            // this.loadQuantityTypes();
            // this.loadInvoiceTypes();
            this.loadVehicles();
            this.loadServices();
            // this.loadPaymentTypes();

            if(this.$route.params.id > 0)
            {
              this.form.id = parseInt(this.$route.params.id)
              this.editmode = true;
              // this.loadInvoice();
            }
            else
            {
              this.form.start_date = this.currentDate();
              this.form.start_time = this.currentTime();
              this.form.end_date = this.currentDate();
              this.form.end_time = this.nextHour();
              // console.log(this.form.start_time);

              // this.loadAreas();
            }

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
