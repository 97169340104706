export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/booking', component: require('./components/booking/Index.vue').default },
    { 
        path: '/booking/edit/:id?', 
        component: require('./components/booking/Edit.vue').default,
        props: true,
        meta: { reuse: false }
    },
    { 
        path: '/booking/place/:id?', 
        component: require('./components/booking/Place.vue').default,
        props: true,
        meta: { reuse: false }
    },
    // { path: '/invoices/download', component: require('./components/booking/Download.vue').default },

    { path: '/income', component: require('./components/income/Index.vue').default },

    // { path: '/settings/index', component: require('./components/settings/Index.vue').default },
    { path: '/settings/services', component: require('./components/Services/Index.vue').default },
    // { path: '/settings/invoiceSeries', component: require('./components/booking/InvoiceSeries.vue').default },
    { path: '/settings/PaymentTypes', component: require('./components/paymentTypes/Index.vue').default },
    { path: '/settings/vehicles', component: require('./components/vehicles/Index.vue').default },
    { path: '/settings/places', component: require('./components/places/Index.vue').default },
    // { path: '/settings/quantityTypes', component: require('./components/invoices/QuantityTypes.vue').default },
    { path: '/settings/roles', component: require('./components/settings/Roles.vue').default },

    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    
    { path: '*', component: require('./components/NotFound.vue').default }
];
