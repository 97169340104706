<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Προσωπικό</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη προσωπικού
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Όνομα</th>
                      <th>Email</th>
                      <th>Τηλέφωνο</th>
                      <th>Ημ. δημιουργίας</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">

                      <td>{{user.id}}</td>
                      <td class="text-capitalize">{{user.name}}</td>
                      <td>{{user.email}}</td>
                      <td><div v-if="user.phone != null"><a v-bind:href="'tel:' + user.phone">{{user.phone}}</a></div></td>
                      <td>{{ customDateFormatter(user.created_at) }}</td>

                      <td>
                        <button class="bg-primary btn btn-sm" title="Προβολή ωρών προσωπικού" @click="showHours(user.id)">
                            <i class="fa fa-list white"></i>
                        </button>
                        <button class="bg-primary btn btn-sm" title="Επεξεργασία προσωπικού" @click="editModal(user)">
                            <i class="fa fa-edit white"></i>
                        </button>
                        <button class="bg-danger btn btn-sm" title="Διαγραφή προσωπικού" @click="deleteUser(user.id)">
                            <i class="fa fa-trash white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Προσθήκη προσωπικού</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία προσωπικού</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.name"
                                label="Όνοματεπώνυμο*"
                                :rules="['Required']"
                                :class="{ 'is-invalid': form.errors.has('name') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.email"
                                required
                                label="Email"
                                :class="{ 'is-invalid': form.errors.has('email') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.phone"
                                label="Τηλέφωνο"
                                :class="{ 'is-invalid': form.errors.has('phone') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.password"
                                label="Κωδικός πρόσβασης"
                                :class="{ 'is-invalid': form.errors.has('password') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="password"></has-error>
                        </div>
                        <div class="form-group">
                            <v-select
                                dense
                                v-model="form.type"
                                label="Ρόλος*"
                                :rules="['Required']"
                                :class="{ 'is-invalid': form.errors.has('type') }"
                                :items="roles"
                                item-text="name"
                                item-value="id"
                            >
                            </v-select>
                            <has-error :form="form" field="type"></has-error>
                        </div>

                        <h5>Υπηρεσίες</h5>
                        <div>
                            <div class="form-group" v-for="service in services" v-bind:key="service.id">

                                <v-checkbox
                                        v-model="form.services"
                                        :label="service.name"
                                        :value = "service.id"
                                        :fieldId="service.id"
                                        :checked="form.services.includes(service.id)"
                                    >
                                </v-checkbox>

                            </div>
                        </div>
                        <!--<div class="form-group">
                            <v-select
                                dense
                                :chips="true"
                                :multiple="true"
                                v-model="form.permissions"
                                label="Προεπιλεγμένα δικαιώματα"
                                :items="permissions"
                                item-text="name"
                                item-value="id"
                            >
                            </v-select>
                        </div> -->

                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>




          <!-- Modal -->
          <div class="modal fade" id="hours" tabindex="-1" role="dialog" aria-labelledby="hours" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ώρες προσωπικού</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="getStaffHours()">
                    <div class="modal-body">
                        <br>
                        <div class="row">
                            <div class="form-group col-md-6">
                            <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                    <datepicker 
                                      :format="customDateFormatter" 
                                      v-model="start_date"
                                      @closed="dateSelected"
                                    ></datepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-6">
                            <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Έναρξης</label>
                                    <datepicker 
                                      :format="customDateFormatter" 
                                      v-model="end_date"
                                      @closed="dateSelected"
                                    ></datepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                    </div>
                  </form>
                  <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Υπηρεσία</th>
                      <th>Έναρξη</th>
                      <th>Λήξη</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="booking in bookings" :key="booking.id">
                      <td>{{ booking.id }}</td>
                      <td>{{ (servicesObject?servicesObject[booking.service_id]:'') }}</td>
                      <td>{{ customDateFormatter(booking.start_date) + " " + booking.start_time }}</td>
                      <td>{{ customDateFormatter(booking.end_date) + " " + booking.end_time }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-12">
                <br>
                <div v-for="(value, key) in sums" :key="key">
                  <div>{{ servicesObject[key] }}</div>
                  <strong>{{ value }}</strong>
                </div>
              </div>
            </div>
            </div>

            </div>





    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {getDate, currentDate} from '../common-functions.js';


    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                users : {},
                form: new Form({
                    id : '',
                    type : 2,
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    email_verified_at: '',
                    services: []
                    // permissions: '',
                }),
                user_id: '',
                start_date: '',
                end_date: '',
                bookings: {},
                // invoiceSeries: [],
                services : [],
                servicesObject : {},
                roles : [],
                sums : {},
            }
        },
        methods: {

            loadRoles(){
                axios.get("/api/roles/getList").then(({ data }) => (this.roles = data.data));
            },
            loadServices(){
                axios.get("/api/services/getListKeyValue").then(({ data }) => {
                    this.services = data.data;
                    let s = {};
                    for (let i = 0; i < this.services.length; i++)
                        s[this.services[i]['id']] = this.services[i]['name'];
                    this.servicesObject = s;
                });
            },
            getResults(page = 1) {
                this.$Progress.start();
                axios.get('api/user?page=' + page).then(({ data }) => {
                  this.users = data.data;
                });
                this.$Progress.finish();
            },
            getStaffHours(){
                axios.get("/api/booking/getList?" + `user_id=${this.user_id}&start_date=${this.start_date}&end_date=${this.end_date}`  ).then(({ data }) => {
                  this.bookings = data;
                  let sum = {};
                  for (let i = 0; i < data.length; i++)
                  {
                    if(typeof sum[data[i].service_id] == 'undefined')
                      sum[data[i].service_id] = 0;

                    sum[data[i].service_id] += parseInt(data[i].hours);
                  }

                  this.sums = sum;
                });
            },

            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            customDateFormatter(date) {
                if(!date)
                    return '';
                return this.$moment(date).format('DD/MM/yyyy');
            },
            dateSelected(){
                if(this.start_date instanceof Date)
                    this.start_date = getDate(this.start_date);
                if(this.end_date instanceof Date)
                    this.end_date = getDate(this.end_date);

                this.getStaffHours();
            },





            editModal(user){
                this.editmode = true;
                user['type'] = parseInt(user['type']);

                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
          loadUsers(){
            this.$Progress.start();
            axios.get("api/user").then(({ data }) => (this.users = data.data));
            this.$Progress.finish();
          },
          
          createUser(){

              this.form.post('api/user')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },



          showHours(id){
            $('#hours').modal('show');
            this.getStaffHours();
            this.user_id = id;
          },

        },
        mounted() {
            // console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadServices();
            this.loadRoles();
            this.loadUsers();
            this.start_date = currentDate();
            this.end_date = currentDate();
            this.$Progress.finish();
        }
    }
</script>
